@use "sass:list";
@use "@angular/material" as mat;
@use "@exl-ng/mulo-core/lib/styles/material/core" as mulo;
@use "select";
@use "menu";

/* Include the theme file for each Material component that is used in the
    app. This to enable theme customization, typography settings and
    exlibris-extensions of Material components. Components are added
    individually (instead of the entire Material library), in order to keep the
    build as slim as possible. For this reason, make sure the component is not
    defined here already before adding a new one, */
$material-components: (
    autocomplete,
    bottom-sheet,
    button,
    button-toggle,
    card,
    checkbox,
    chips,
    datepicker,
    dialog,
    divider,
    expansion-panel,
    form-field,
    icon,
    input,
    list,
    menu,
    optgroup,
    option,
    progress-bar,
    progress-spinner,
    radio,
    ripple,
    ripple,
    select,
    sidenav,
    slide-toggle,
    slider,
    snackbar,
    stepper,
    tabs,
    tooltip,
    tree
);

mulo.$material-components: $material-components;
@mixin local-angular-material-theme($theme) {
    @include mulo.material-extensions($theme);

    @if list.index($material-components, select) {
        @include select.mat-select-extension;
    }
    @if list.index($material-components, menu) {
        @include menu.mat-menu-extension;
    }
}
