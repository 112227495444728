@use "@exl-ng/mulo-core" as *;
@use "@exl-ng/mulo-core/lib/styles/theme/palette-helpers" as ph;
@use "styles/exl-common-styles" as esp;
@use "swiper/swiper-bundle.css" as *;

@use "@exl-ng/mulo-core/lib/styles/froala";
@use "@exl-ng/mulo-core/lib/styles/quill";
@use "@exl-ng/mulo-core/lib/styles/baseline/reset";

$app-theme-highlight: #ffe564;

/* This MUST BE INCLUDED HERE in order for material components to work correctly */
@include esp.init-material-components;

:root {
    // app-specific colors
    --palette-orcid: #{ph.palette-set-color(#a6ce39)};
}

.rich-text-reset {
    @include reset.revert;
    @include froala.fr-view;
    @include quill.ql-view;
}

.spacer {
    flex: 1 1 0;
}
