// Layouts
// ---------------------------------------

//  usage: 'exl-layout--header-main-footer'
.exl-layout--header-main-footer {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .exl-main {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
