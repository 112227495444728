@forward "material/core";
@forward "material/all-material-extensions";
@forward "variables";
@forward "baseline";
@forward "mixins";

@use "./theme/material-vars-base" as base;
@import "theme/default-vars";

/* Init material components and vars */
@mixin init-material-components() {
    /*s*/
    @include base.init-material-css-vars;
}
