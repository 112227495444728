@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;
@use "angular-material-css-vars/src/lib/variables" as amcv;
@use "material/core";
@use "@exl-ng/mulo-core/lib/styles/theme/default-vars" as mulo;

@include mat.ripple;
@include cdk.a11y-visually-hidden;
@include cdk.overlay;
@include cdk.text-field;

$esp-light-theme: map.merge(mulo.$default-light-theme, (/* Overrides here */));
$esp-palette-background: map.merge(
    mulo.$palette-background,
    (/* Overrides here */)
);

@mixin mat-css-root($varMap: null) {
    @at-root :root {
        @each $varName, $varValue in $varMap {
            @if meta.type-of($varValue) == string {
                #{$varName}: $varValue; // to prevent quotes interpolation
            } @else {
                #{$varName}: #{$varValue};
            }
        }
    }
}

@mixin init-mat-theme($dark-theme-selector) {
    @include mat.elevation-classes();
    @include mat.app-background();

    $primary: mat.m2-define-palette(mulo.$palette-primary);
    $accent: mat.m2-define-palette(mulo.$palette-accent);
    $warn: mat.m2-define-palette(mulo.$palette-warn);
    $typography: mat.m2-define-typography-config(
        $font-family: mulo.$font-family,
        $body-1: mat.m2-define-typography-level(
                16px,
                22px,
                400,
                mulo.$font-family
            ),
        $body-2: mat.m2-define-typography-level(
                15px,
                22px,
                600,
                mulo.$font-family
            ),
        $button: mat.m2-define-typography-level(
                14px,
                36px,
                600,
                mulo.$font-family
            ),
    );

    $theme: mat.m2-define-light-theme(
        (
            color: (
                primary: $primary,
                accent: $accent,
                warn: $warn,
            ),
            typography: $typography,
        )
    );

    @include mat.all-component-themes($theme);

    @at-root {
        @include core.local-angular-material-theme($theme);
    }
}

@mixin init-material-css-vars(
    $theme: $esp-light-theme,
    $dark-theme-selector: amcv.$dark-theme-selector,
    $default-theme-text: amcv.$text
) {
    @include init-mat-theme($dark-theme-selector);

    @include mat-css-root($default-theme-text);
    @include mat-css-root($theme);
}
