html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    // text-rendering: $render-mode;
}

html, body {
    height: 100%;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
    display: block;
}
