@use "@exl-ng/mulo-core/lib/styles/material/menu" as mulo;

@mixin mat-menu-extension() {
    @include mulo.mat-menu-extension;

    .mat-mdc-menu-item {
        .mat-icon {
            display: inline-flex;
        }
    }
}
