@use "@exl-ng/mulo-core/lib/styles/material/select" as mulo;

@mixin mat-select-extension() {
    @include mulo.mat-select-extension;

    .mat-mdc-select-panel {
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
        mat-option.mat-mdc-option {
            height: auto;
            min-height: 3em;
            padding-block: 0.5rem;
            line-height: normal;
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
            .mat-option-text {
                white-space: normal;
            }
        }
    }
}

/* Microsoft Edge fixes */
.__browser-ms-edge {
    .mat-mdc-form-field {
        /* fix select flex behavior occuppying the entire container width*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-mdc-form-field-infix {
            flex-grow: 0;
        }
    }
}
